import React, { useState } from 'react';
import { Stack, Select, MenuItem } from '@mui/material';
import TitleComponent from './TitleComponent';

const HeaderComponent = () => {
  const [selectedContract, setSelectedContract] = useState('Client');
  return (
    <Stack direction="column" spacing={2} sx={{ height: 130, width: '100%', backgroundColor: '#f9f9f9', borderRadius: 2 }}>
      <TitleComponent number="1" label="Tipo de Contrato:" />
      <Stack direction="row" spacing={2}>
        <Select
          sx={{ marginLeft: 2, width: 150 }}
          onChange={(e) => setSelectedContract(e.target.value)}
          value={selectedContract}
          disabled
        >
          <MenuItem value="Client">Cliente</MenuItem>
          <MenuItem value="Rl">Rep. Legales</MenuItem>
          <MenuItem value="Aval">Avales</MenuItem>
        </Select>
      </Stack>
    </Stack>
  );
};

export default HeaderComponent;
