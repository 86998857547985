import React from 'react';
import { Tooltip, Stack, Divider } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PropTypes from 'prop-types';
import TitleComponent from './TitleComponent';
import { legalRepresentativesInput } from './constants/contract-chip-inputs';

const LegalRepresentativesComponent = ({ legalRepresentatives, setLegalRepresentatives }) => {
  const handleAddLegalRepresentative = () => {
    const newItem = [{
      id: legalRepresentatives.length,
      label: 'SR',
      name: '',
      rut: '',
      nationality: 'chileno',
      profession: '',
      email: '',
      civilStatus: 'single',
      mainDisabled: false,
    }];
    setLegalRepresentatives(legalRepresentatives.concat(newItem));
  };

  const handleLegalRepresentativeInfo = (eventId, eventValue, legalRepresentativeId) => {
    const newItem = legalRepresentatives.find((item) => item.id === legalRepresentativeId);
    newItem[eventId] = eventValue;
    setLegalRepresentatives(legalRepresentatives.map((u) => (u.id !== newItem.id ? u : newItem)));
  };

  const handleDeleteLegalRepresentative = (legalRepresentativeId) => {
    setLegalRepresentatives(
      legalRepresentatives.filter((item) => item.id !== legalRepresentativeId),
    );
  };

  return (
    <>
      <TitleComponent number="2.1" label="Representantes Legales:">
        <AddCircleIcon
          color="primary"
          onClick={handleAddLegalRepresentative}
          sx={{ cursor: 'pointer' }}
        />
      </TitleComponent>
      <Stack alignItems="center" spacing={2}>
        {legalRepresentatives.map((lr, index) => (
          <Stack direction="column" key={lr.id}>
            <TitleComponent number={index + 1}>
              <Divider width="75%" />
              <Tooltip title={`Eliminar Representante Legal ${lr.name}`}>
                <RemoveCircleIcon
                  color="primary"
                  onClick={() => handleDeleteLegalRepresentative(lr.id)}
                  sx={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </TitleComponent>
            <Stack alignItems="center" spacing={2} sx={{ marginTop: 2 }}>
              {legalRepresentativesInput.map((rowsInput) => (
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ width: '100%' }}
                  key={`legal-representative-row-${rowsInput[0].index}`}
                >
                  {rowsInput.map((input) => (
                    <input.component
                      key={`legal-representative-input-${input.id}-${lr.id}`}
                      input={input}
                      obj={lr}
                      handleInfoFunc={handleLegalRepresentativeInfo}
                    />
                  ))}
                </Stack>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

LegalRepresentativesComponent.propTypes = {
  legalRepresentatives: PropTypes.arrayOf(PropTypes.shape).isRequired,
  setLegalRepresentatives: PropTypes.func.isRequired,
};

export default LegalRepresentativesComponent;
