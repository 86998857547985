import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import PropTypes from 'prop-types';

const UploadDropZoneDialogContainer = ({
  files,
  setFiles,
  errorMutation,
  setErrorMutation,
  filesType,
}) => {
  const onDrop = (acceptedFiles) => { setFiles(acceptedFiles); };
  const { getRootProps, isDragActive } = useDropzone({ onDrop });
  const boxTypography = () => {
    if (files.length === 1) {
      return (
        <Typography variant="h6" sx={{ margin: 2, wordBreak: 'break-word' }}>{files[0].name}</Typography>
      );
    }
    if (files.length > 1) {
      return (
        <Typography variant="h6" sx={{ borderRadius: 5, borderStyle: 'solid', borderWidth: '1px', padding: '10px' }}>
          {files.length} {filesType} Cargados
        </Typography>
      );
    }

    return (
      <><Typography variant="caption">Arrastra aquí el archivo que quieres subir</Typography>
        <Typography variant="caption" textAlign="center" mt={1}>ó</Typography>
      </>
    );
  };
  const ConditionalFilling = useMemo(() => {
    if (isDragActive) {
      return (
        <Grid item container direction="row" alignItems="center" justifyContent="center">
          <CloudUpload color="primary" sx={{ marginRight: 1 }} />
          <Typography>
            Suelta el archivo aquí!
          </Typography>
        </Grid>
      );
    }
    return (
      <Stack
        width="100%"
        height="100%"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Typography
          variant="caption"
          width="90%"
          display="block"
          color="text.main"
        >
          {files && errorMutation && errorMutation.split('\n').map((i, key) => <p key={`error-${key + 1}`}>{i}</p>)}
        </Typography>
        <Box minHeight="20%" sx={{ display: 'flex', flexDirection: 'column' }}>
          {boxTypography()}
        </Box>

        <Typography
          variant="caption"
          width="180px"
          color="primary"
          sx={{ textDecoration: 'underline' }}
        >Sube desde tu dispositivo
        </Typography>
      </Stack>
    );
  }, [isDragActive, errorMutation, files]);
  return (
    <Grid
      {...getRootProps()}
      onClick={(props) => { getRootProps().onClick(props); setErrorMutation(''); }}
      sx={{
        borderColor: 'primary.main',
        backgroundColor: isDragActive ? 'primary.unreadNotification' : 'gray.light',
        borderStyle: isDragActive ? 'dashed' : 'none',
        borderRadius: 5,
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        transition: 'border 0.24s ease-in-out' }}
      container
      justifyContent="center"
      alignItems="center"
    >
      {ConditionalFilling}
    </Grid>
  );
};
UploadDropZoneDialogContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  errorMutation: PropTypes.string,
  setErrorMutation: PropTypes.func.isRequired,
  filesType: PropTypes.string,
};
UploadDropZoneDialogContainer.defaultProps = {
  errorMutation: null,
  filesType: 'Archivos',
};
export default UploadDropZoneDialogContainer;
