import { Add } from '@mui/icons-material';
import { ListSubheader, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React from 'react';
import useGetUserToken from '../../hooks/useGetUserToken';
import { LoadingIconButton } from '../buttons';

const GetUserToken = () => {
  const [userId, setUserId, [getUserToken, { loadingGetUserToken }]] = useGetUserToken();
  return (
    <>
      <ListSubheader
        id="nested-list-subheader-log-with-new-user"
        component={Typography}
        variant="h6"
        color="primary"
      >
        Loguearse como usuario
      </ListSubheader>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ ml: 3 }}>
        <TextField
          variant="outlined"
          name="userIdNewLogged"
          placeholder="Id usuario"
          onChange={(event) => setUserId(event.target.value)}
          value={userId}
        />
        <Tooltip title="Loguearse como usuario">
          <LoadingIconButton
            onClick={getUserToken}
            sx={{ p: 0 }}
            loading={loadingGetUserToken}
            size="small"
            color="primary"
          >
            <Add />
          </LoadingIconButton>
        </Tooltip>
      </Stack>
    </>
  );
};

export default GetUserToken;
