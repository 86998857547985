const setMultipleLegalRepresentatives = (company, setFunc) => {
  const lrArray = company.legalRepresentative || [];
  setFunc(lrArray.map((end, index) => (
    {
      id: index,
      masterEntityId: end.relatedPerson.id,
      label: end.label || 'SR',
      name: end.relatedPerson.name,
      rut: end.relatedPerson.displayNationalIdentifier,
      email: end.email?.toLowerCase() || '',
      nationality: end.nationality?.toLowerCase() || 'chileno',
      profession: end.profession?.toLowerCase() || '',
      civilStatus: end.civilStatus?.toLowerCase() || 'single',
      mainDisabled: true,
    })));
};

const setMultipleEndorsements = (company, setFunc) => {
  const endArray = company.endorsements || [];
  setFunc(endArray.map((rep, index) => (
    {
      id: index,
      masterEntityId: rep.relatedPerson.id,
      label: rep.label || 'SR',
      name: rep.relatedPerson.name,
      rut: rep.relatedPerson.displayNationalIdentifier,
      personeriaComments: rep.personeriaComments || '',
      nationality: rep.nationality?.toLowerCase() || 'chileno',
      profession: rep.profession?.toLowerCase() || '',
      civilStatus: rep.civilStatus?.toLowerCase() || 'single',
      address: rep.address?.toLowerCase() || '',
      email: rep.email?.toLowerCase() || '',
      mainDisabled: true,
      spouceName: rep.relatedSpouce?.name || '',
      spouceLabel: rep.spouceLabel || 'SRA',
      spouceMasterEntityId: rep.relatedSpouce?.id || null,
      spouceRut: rep.relatedSpouce?.displayNationalIdentifier || '',
      spouceAddress: rep.spouceAddress?.toLowerCase() || '',
      spouceNationality: rep.spouceNationality?.toLowerCase() || '',
    })));
};

const setNaturalPersonLegalRepresentative = (company, setFunc) => {
  setFunc([{
    id: 0,
    masterEntityId: company.masterEntity.id,
    label: 'SR',
    name: company.name,
    rut: company.masterEntity.displayNationalIdentifier,
    email: '',
    nationality: 'chileno',
    profession: '',
    civilStatus: 'single',
    mainDisabled: true,
  }]);
};

const setNaturalPersonEndorsement = (company, setFunc) => {
  setFunc([{
    id: 0,
    masterEntityId: company.masterEntity.id,
    label: 'SR',
    name: company.name,
    personeriaComments: '',
    rut: company.masterEntity.displayNationalIdentifier,
    nationality: 'chileno',
    profession: '',
    civilStatus: 'single',
    address: company.address?.toLowerCase() || '',
    email: '',
    mainDisabled: true,
    spouceName: '',
    spouceRut: '',
    spouceAddress: '',
    spouceNationality: '',
  }]);
};

const setInfo = (company, setFunc) => {
  setFunc({
    mainActivity: company.mainActivity?.toLowerCase() || '',
    address: company.address?.toLowerCase() || '',
    rut: company.masterEntity.displayNationalIdentifier,
    societyType: '',
    name: company.name,
    mainDisabled: true,
  });
};

const HandleResponse = (company, setContractInfo, setLegalRepresentatives, setEndorsements) => {
  setInfo(company, setContractInfo);
  if (company.isNaturalPerson) {
    if (!company.legalRepresentative) {
      setNaturalPersonLegalRepresentative(company, setLegalRepresentatives);
    } else {
      setMultipleLegalRepresentatives(company, setLegalRepresentatives);
    }
    if (!company.endorsements) {
      setNaturalPersonEndorsement(company, setEndorsements);
    } else {
      setMultipleEndorsements(company, setEndorsements);
    }
  } else {
    setMultipleEndorsements(company, setEndorsements);
    setMultipleLegalRepresentatives(company, setLegalRepresentatives);
  }
};

export default HandleResponse;
